// @flow
import React, {Fragment} from 'react';
import {Block} from 'baseui/block';
import {styled} from 'baseui';
import {StatefulTabs, Tab} from 'baseui/tabs';
//import CommunityList from './CommunityList'

const Headline = styled('div', ({$theme}) => ({
  margin: '0px',
  marginLeft: '0px',
  width: '100%',
  ...$theme.typography.font900
}));

const SummaryOne = styled('div', ({$theme}) => ({
  paddingTop: '20px',
  paddingBottom: '20px',
  width: '375px',
  ...$theme.typography.font400
}));

const SummaryTwo = styled('div', ({$theme}) => ({
  paddingTop: '20px',
  paddingBottom: '20px',
  width: '620px',
  ...$theme.typography.font400
}));

const SummaryThree = styled('div', ({$theme}) => ({
  paddingTop: '20px',
  paddingBottom: '20px',
  width: '900px',
  ...$theme.typography.font400
}));

const OneColumn = styled('div', ({$theme}) => ({
  display: 'none',
  '@media screen and (max-width: 675px)': {
    display: 'block',
  },
}));

const TwoColumn = styled('div', ({$theme}) => ({
  display: 'none',
  '@media screen and (min-width: 675px) and (max-width: 1000px)': {
    display: 'block',
  },
}));

const ThreeColumn = styled('div', ({$theme}) => ({
  display: 'none',
  '@media screen and (min-width: 1000px)': {
    display: 'block',
  },
}));

const FullWidth = styled('div', ({$theme}) => ({
  display: 'none',
  '@media screen and (min-width: 1000px)': {
    display: 'block',
  },
}));

export default class Principles extends React.Component {
  constructor() {
    super()
    this.state = {
      principles: [],
      summary: {}
    }
  }

  componentWillMount() {
    let state = window.store.getState()
    let principles = state.principlesState.principles;

    this.setState({
      headline: principles.headline,
      summary: principles.summary,
      tabs: principles.tabs
    })
  }

  render() {
    return (
      <div>
        <Block
          display="grid"
          justifyItems="center"
          justifyContent="center"
          width="100%"
          paddingTop="120px"
        >
          <Block
            display="grid"
            justifyItems="left"
            justifyContent="left"
            gridTemplateColumns={'repeat(1, auto)'}
            gridGap="scale600"
            width="100%"
          >
            <Block>
              <Headline>{this.state.headline}</Headline>
              <OneColumn>
                <SummaryOne>
                  <span dangerouslySetInnerHTML={{__html: this.state.summary}} />
                </SummaryOne>
              </OneColumn>
              <TwoColumn>
                <SummaryTwo>
                  <span dangerouslySetInnerHTML={{__html: this.state.summary}} />
                </SummaryTwo>
              </TwoColumn>
              <ThreeColumn>
                <SummaryThree>
                  <span dangerouslySetInnerHTML={{__html: this.state.summary}} />
                </SummaryThree>
              </ThreeColumn>
            </Block>
            <StatefulTabs initialState={{activeKey: '0'}} renderAll>
              <Tab title={this.state.tabs[0].label}>
                <OneColumn>
                  <SummaryOne>
                    <span dangerouslySetInnerHTML={{__html: this.state.tabs[0].content}} />
                  </SummaryOne>
                </OneColumn>
                <TwoColumn>
                  <SummaryTwo>
                    <span dangerouslySetInnerHTML={{__html: this.state.tabs[0].content}} />
                  </SummaryTwo>
                </TwoColumn>
                <ThreeColumn>
                  <SummaryThree>
                    <span dangerouslySetInnerHTML={{__html: this.state.tabs[0].content}} />
                  </SummaryThree>
                </ThreeColumn>
              </Tab>
              <Tab title={this.state.tabs[1].label}>
              <SummaryThree>
                <span dangerouslySetInnerHTML={{__html: this.state.tabs[1].content}} />
              </SummaryThree>
              </Tab>
              <Tab title={this.state.tabs[2].label}>
              <SummaryThree>
                <span dangerouslySetInnerHTML={{__html: this.state.tabs[2].content}} />
              </SummaryThree>
              </Tab>
            </StatefulTabs>
          </Block>
        </Block>
      </div>
    );
  }
}
