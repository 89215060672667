import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from "./store/index";
import {
  addContent,
  addSettings,
  addCommunity,
  addPrinciples,
  addProjects,
  addAbout,
  addGithub,
  addTrademarks,
  addFooter
} from "./actions/index";

import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider, LightTheme} from 'baseui';
import { Client as Styletron } from "styletron-engine-atomic";

const engine = new Styletron();

window.store = store;
window.addContent = addContent;
window.addSettings = addSettings;
window.addCommunity = addCommunity;
window.addPrinciples = addPrinciples;
window.addProjects = addProjects;
window.addAbout = addAbout;
window.addGithub = addGithub;
window.addTrademarks = addTrademarks;
window.addFooter = addFooter;

ReactDOM.render(
  <StyletronProvider value={engine}>
  <BaseProvider theme={LightTheme}>
    <App />
  </BaseProvider>
  </StyletronProvider>,
  document.getElementById('root')
);
