// @flow
import React from 'react';
import {Block} from 'baseui/block';
import {styled} from 'baseui';
import GitHubList from './GitHubList'

const Headline = styled('div', ({$theme}) => ({
  margin: '0px',
  width: '100%',
  ...$theme.typography.font900
}));

const Summary = styled('div', ({$theme}) => ({
  ...$theme.typography.font400
}));

const OneColumn = styled('div', ({$theme}) => ({
  paddingTop: '30px',
  display: 'none',
  '@media screen and (max-width: 675px)': {
    display: 'block',
  },
}));

const TwoColumn = styled('div', ({$theme}) => ({
  paddingTop: '30px',
  display: 'none',
  '@media screen and (min-width: 675px) and (max-width: 1000px)': {
    display: 'block',
  },
}));

const ThreeColumn = styled('div', ({$theme}) => ({
  paddingTop: '30px',
  display: 'none',
  '@media screen and (min-width: 1000px)': {
    display: 'block',
  },
}));

export default class GitHub extends React.Component {
  constructor() {
    super()
    this.state = {
      repos: [],
      summary: {}
    }
  }

  componentWillMount() {
    let state = window.store.getState()
    let github = state.githubState.github;

    this.setState({
      repos: github.list,
      summary: github.summary
    })
  }

  render() {
    return (
      <div>
        <Block
          display="grid"
          justifyItems="center"
          justifyContent="center"
          width="100%"
          paddingTop="120px"
        >
          <Block
            display="grid"
            justifyItems="left"
            justifyContent="left"
            gridTemplateColumns={'repeat(1, auto)'}
            gridGap="scale600"
            width="97%"
          >
            <Headline>GitHub</Headline>
            <Summary>
              {this.state.summary}
            </Summary>
          </Block>

          <OneColumn>
            <Block
              display="grid"
              justifyItems="center"
              justifyContent="center"
              gridTemplateColumns={'repeat(1, auto)'}
              gridGap="scale400"
              width="100%"
            >
              <GitHubList repos={this.state.repos} />
            </Block>
          </OneColumn>

          <TwoColumn>
            <Block
              display="grid"
              justifyItems="center"
              justifyContent="center"
              gridTemplateColumns={'repeat(2, auto)'}
              gridGap="scale400"
              width="100%"
            >
              <GitHubList repos={this.state.repos} />
            </Block>
          </TwoColumn>

          <ThreeColumn>
            <Block
              display="grid"
              justifyItems="center"
              justifyContent="center"
              gridTemplateColumns={'repeat(3, auto)'}
              gridGap="scale400"
              width="100%"
            >
              <GitHubList repos={this.state.repos} />
            </Block>
          </ThreeColumn>
        </Block>
      </div>
    );
  }
}
