import React from "react";
import {Card, StyledBody, StyledAction} from 'baseui/card';
import {styled} from 'baseui';
import IconGithub from './icons/IconGithub0';
import {Button} from 'baseui/button';

const RepoDescription = styled('div', ({$theme}) => ({
  height: '65px',
}));

export default class GithubList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let that = this;

    return (
      that.props.repos.map(function(item) {
        return (
          <Card
            key={item.id}
            overrides={{Root: {style: {width: '300px', margin: '15px'}}}}
            title={item.name}
            justifyitems="center"
            justifycontent="center"
          >
            <StyledBody>
            <RepoDescription>
              {item.description}
              </RepoDescription>
            </StyledBody>
            <StyledAction>
              <Button
                overrides={{
                  BaseButton: {
                    style: {
                      width: '200px',
                      margin: '10px',
                      backgroundColor: '#ff8d00',
                      ":hover": {
                        backgroundColor: '#ED6F0E',
                      }
                    }
                  }
                }}
                startEnhancer={() => <IconGithub size="18" />}
                $as="a"
                href={item.link}
                target="_blank"
              >
                View on GitHub
              </Button>
            </StyledAction>
          </Card>
        )
      })
    )
  }
};
