import React from "react";
//import { BrowserRouter as Router, Route } from "react-router-dom";
//import { BrowserRouter, Route } from "react-router-dom";
import { HashRouter, Route } from 'react-router-dom';
import mainJSON from './main.json'
import Home from './components/Home'
import Community from './components/Community'
import Principles from './components/Principles'
import GitHub from './components/GitHub'
import Projects from './components/Projects'
import Trademarks from './components/Trademarks'
import Navigation from './components/Navigation'
import Footer from './components/Footer'
import {LightThemeMove, ThemeProvider} from 'baseui';

function Main() {
  window.store.dispatch(window.addContent(mainJSON.page_content))
  window.store.dispatch(window.addSettings(mainJSON.page_settings))
  window.store.dispatch(window.addCommunity(mainJSON.community))
  window.store.dispatch(window.addPrinciples(mainJSON.principles))
  window.store.dispatch(window.addProjects(mainJSON.projects))
  window.store.dispatch(window.addAbout(mainJSON.about))
  window.store.dispatch(window.addFooter(mainJSON.footer))
  window.store.dispatch(window.addGithub(mainJSON.github))
  window.store.dispatch(window.addTrademarks(mainJSON.trademarks))

  return (
    <ThemeProvider theme={LightThemeMove}>
      <div>
        <Navigation />
        <HashRouter basename={process.env.PUBLIC_URL}>
]           <Route exact path='/' component={Home} />
            <Route path='/projects' component={Projects} />
            <Route path='/community' component={Community} />
            <Route path='/principles' component={Principles} />
            <Route path='/github' component={GitHub} />
            <Route path='/trademarks' component={Trademarks} />
        </HashRouter>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default Main;

/*
<BrowserRouter basename={process.env.PUBLIC_URL}>
  <Switch>
    <Route exact path='/' component={Home} />
    <Route path='/projects' component={Projects} />
    <Route path='/community' component={Community} />
    <Route path='/github' component={GitHub} />
  </Switch>
</BrowserRouter>
*/
