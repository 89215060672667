import React from 'react';
import {Button, KIND, SIZE} from 'baseui/button';
import IconGithub from './icons/IconGithub';
import IconBlog from '@uber/icons/link-filled';
import IconFacebook from './icons/IconFacebookFilled';
import IconTwitter from './icons/IconTwitterFilled';
import IconYoutube from '@uber/icons/youtube-filled';
import IconMedium from './icons/IconMedium';
import IconSlackFilled from './icons/IconSlackFilled'

const iconTable ={
  github: {
    name: "GitHub",
    logo: <IconGithub size="18" />
  },
  blog: {
    name: "Blog",
    logo: <IconBlog size="18" />
  },
  facebook: {
    name: "Facebook",
    logo: <IconFacebook size="24" />
  },
  twitter: {
    name: "Twitter",
    logo: <IconTwitter size="24" />
  },
  medium: {
    name: "Medium",
    logo: <IconMedium size="18" />
  },
  youtube: {
    name: "YouTube",
    logo: <IconYoutube size="18" />
  },
  slack: {
    name: "Slack",
    logo: <IconSlackFilled size="24" />
  }
}

const buttonOverrides = { BaseButton: {
  style: ({$theme}) => {
    return {
      ...$theme.typography.font400,
      textDecoration: 'none',
      color: $theme.colors.mono1000,
      justifyContent: 'left',
      ':hover': {
        backgroundColor: $theme.colors.mono1000,
      },
    };
  },
}};

export default class FooterLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttons: []
    };
  }

  componentWillMount() {
    let buttons = [];
    let linkObject = this.props.links;

    Object.entries(linkObject).forEach(([key, value]) => {
      if (value) {
        buttons.push(
          <Button
            key={key}
            startEnhancer={() => iconTable[key].logo}
            size={SIZE.compact}
            kind={KIND.minimal}
            overrides={buttonOverrides}
            $as="a"
            href={value}
            target="_blank"
            width="50px"
          />
        )
      }

      this.setState({
        buttons: buttons
      })
    });
  }

  render() {
    return (
      <div>
        {this.state.buttons}
      </div>
    )
  }
}
