import React from "react";
import {Card, StyledBody} from 'baseui/card';
import {Block} from 'baseui/block';
import {Avatar} from 'baseui/avatar';
import {styled} from 'baseui';
import ProjectsLinks from './ProjectsLinks';
import ProjectTag from './ProjectTag';

const squaredOverrides = {
  Avatar: {
    style: ({$theme}) => ({
      borderTopLeftRadius: $theme.borders.radius100,
      borderTopRightRadius: $theme.borders.radius100,
      borderBottomRightRadius: $theme.borders.radius100,
      borderBottomLeftRadius: $theme.borders.radius100,
    }),
  },
  Root: {
    style: ({$theme}) => ({
      borderTopLeftRadius: $theme.borders.radius100,
      borderTopRightRadius: $theme.borders.radius100,
      borderBottomRightRadius: $theme.borders.radius100,
      borderBottomLeftRadius: $theme.borders.radius100,
    }),
  },
};

const ProjectDescription = styled('div', ({$theme}) => ({
  margin: '0px',
  width: '100%',
  ...$theme.typography.font300
}));

export default class ProjectList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let that = this;

    return (
        that.props.projects.map(function(item) {

	//console.log("*** Rendering project tile", item);

        return (
          <Card
            key={item.pageid}
            display="flex"
            gridgap="scale800"
            overrides={
              {
                Root: {
                  style: {
                    width: '300px',
                    margin: '10px',
                    border: '0px'
                  }
                }
              }
            }
          >
            <StyledBody>
              <Block
                display="flex"
                flexDirection="row"
                marginBottom="20px"
                flexWrap
              >
                <Block
                  key="1"
                  display="flex"
                  width="200px"
                  height="50px"
                  font="font400"
                >
                  <Avatar
                    overrides={squaredOverrides}
                    name="user name #3"
                    size="scale1000"
                    src={item.logo_url}
                  />
                </Block>
                  <Block
                    key="2"
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                    width="230px"
                    height="50px"
                    font="font500"
                    color="#000000"
                  >
                    {item.name}
                  </Block>
                </Block>
                <Block
                  marginTop="10px"
                  marginBottom="20px"
                >
                  <ProjectDescription>
                    {item.summary}
                  </ProjectDescription>
                </Block>
                <Block
                  marginTop="20px"
                  marginBottom="20px"
                >
                  <ProjectTag category={item.category} />
              </Block>
              <Block
                display="grid"
                justifyItems="left"
                justifyContent="left"
                gridTemplateColumns={'repeat(auto)'}
                width="100%"
              >
                <ProjectsLinks links={item.links} />
              </Block>
            </StyledBody>
          </Card>
        )
      })
    )
  }
};
