import React from 'react';
import {Tag} from 'baseui/tag';

export default class ProjectTag extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      label: ''
    };
  }

  componentDidMount() {
    let that = this;
    let label = "";

    switch(this.props.category) {
      case "machinelearning":
        label = "Machine Learning";
        break;
      case "datainfra":
        label = "Data Infrastructure";
        break;
      case "coreinfra":
        label = "Core Infrastructure";
        break;
      case "web":
        label = "Web";
        break;
      case "mobile":
        label = "Mobile";
        break;
      case "visualization":
        label = "Data Visualization";
        break;
      default:
        label = this.props.category;
        break;
    }

    that.setState({ label: label });
  }

  render() {
    return (
      <div>
        <Tag
          key='0'
          closeable={false}
          variant='light'
          kind='neutral'
        >
          {this.state.label}
        </Tag>
      </div>
    )
  }
}
