import React from "react";
import {Card} from 'baseui/card';
import {Block} from 'baseui/block';
import {Avatar} from 'baseui/avatar';
import {styled} from 'baseui';
import {Button, SIZE} from 'baseui/button';

const squaredOverrides = {
  Avatar: {
    style: ({$theme}) => ({
      borderTopLeftRadius: $theme.borders.radius100,
      borderTopRightRadius: $theme.borders.radius100,
      borderBottomRightRadius: $theme.borders.radius100,
      borderBottomLeftRadius: $theme.borders.radius100,
    }),
  },
  Root: {
    style: ({$theme}) => ({
      borderTopLeftRadius: $theme.borders.radius100,
      borderTopRightRadius: $theme.borders.radius100,
      borderBottomRightRadius: $theme.borders.radius100,
      borderBottomLeftRadius: $theme.borders.radius100,
    }),
  },
};

const AffiliationHeadline = styled('div', ({$theme}) => ({
  textDecoration: 'none',
  justifyContent: 'center',
  color: $theme.colors.mono1000,
  ...$theme.typography.font400
}));

export default class CommunityList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let that = this;

    return (
      that.props.list.map(function(item) {
        return (
          <Card
            key={item.id}
            overrides={{Root: {style: {width: '300px'}}}}
          >
            <Block
              display="grid"
              margin="0px"
              justifyItems="center"
              justifyContent="center"
            >
              <Avatar
                display="grid"
                overrides={squaredOverrides}
                size="72px"
                src={item.image}
              />
            </Block>
            <Block as="br" />
            <Block
              display="grid"
              justifyItems="center"
              justifyContent="center"
            >
              <AffiliationHeadline>
                {item.line_1}
              </AffiliationHeadline>
              <AffiliationHeadline>
                {item.line_2}
              </AffiliationHeadline>
              <Block as="br" />
              <Button
                size={SIZE.compact}
                $as="a"
                href={item.link}
                target="_blank"
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => {
                      return {
                        width: '125px',
                        backgroundColor: '#ff8d00',
                        ":hover": {
                          backgroundColor: '#ED6F0E',
                        }
                      }
                    }
                  }
                }}
              >
                Visit Website
              </Button>
            </Block>
          </Card>
        )
      })
    )
  }
};
