import * as React from 'react';

type PropT = {
  size: number,
  color: string,
};

const IconMedium = (props: PropT) => (
  <svg
    width={props.size}
    height={props.size}
    //className="prefix__octicon prefix__octicon-mark-github"
    //viewBox="0 0 16 16"
    //aria-hidden="true"
    viewBox="-91 49.217 56.693 56.693"
  >
    <path
      fillRule="evenodd"
      //fill={props.color}
      d="M-37.8702,64.6716h-1.9682c-0.7307,0-1.7639,1.054-1.7639,1.7288v24.4576c0,0.6756,1.0331,1.5964,1.7639,1.5964h1.9682  v5.8054h-17.8308v-5.8054h3.732V66.745h-0.1829l-8.715,31.515h-6.7474l-8.6026-31.515h-0.2177v25.7096h3.732v5.8054h-14.9281  v-5.8054h1.9115c0.7873,0,1.8205-0.9209,1.8205-1.5964V66.4005c0-0.6748-1.0332-1.7288-1.8205-1.7288h-1.9115v-5.8054h18.6669  l6.1287,22.8069h0.1686l6.1854-22.8069h18.6108V64.6716z"
    />
  </svg>
);

export default IconMedium;
