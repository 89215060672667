import React from 'react';
import {Button, KIND, SIZE} from 'baseui/button';
import {Block} from 'baseui/block';
import IconGithub from './icons/IconGithub';
import IconDocs from '@uber/icons/book-open-filled';
import IconBlog from '@uber/icons/link-filled';
import IconWeb from '@uber/icons/home-filled';
import IconFacebook from '@uber/icons/facebook-filled';
import IconTwitter from '@uber/icons/twitter-filled';
import IconYoutube from '@uber/icons/youtube-filled';
import IconMedium from './icons/IconMedium';
import IconGitter from './icons/IconGitter'
import IconDiscourse from './icons/IconDiscourse'
import IconSlack from './icons/IconSlack'
import IconDownload from './icons/IconDownload'

const iconTable ={
  github: {
    name: "GitHub",
    logo: <IconGithub size="18" />
  },
  website: {
    name: "Website",
    logo: <IconWeb size="18" />
  },
  docs: {
    name: "Documentation",
    logo: <IconDocs size="18" />
  },
  blog: {
    name: "Blog",
    logo: <IconBlog size="18" />
  },
  facebook: {
    name: "Facebook",
    logo: <IconFacebook size="18" />
  },
  twitter: {
    name: "Twitter",
    logo: <IconTwitter size="18" />
  },
  medium: {
    name: "Medium",
    logo: <IconMedium size="18" />
  },
  youtube: {
    name: "YouTube",
    logo: <IconYoutube size="18" />
  },
  gitter: {
    name: "Gitter",
    logo: <IconGitter size="18" />
  },
  discourse: {
    name: "Discourse",
    logo: <IconDiscourse size="18" />
  },
  slack: {
    name: "Slack",
    logo: <IconSlack size="18" />
  },
  download: {
    name: "Download",
    logo: <IconDownload size="18" />
  }
}

const buttonOverrides = { BaseButton: {
  style: ({$theme}) => {
    return {
      ...$theme.typography.font400,
      textDecoration: 'none',
      color: $theme.colors.mono1000,
      justifyContent: 'left',
      ':hover': {
        backgroundColor: $theme.colors.mono100,
      },
    };
  },
}};

export default class ProjectsLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttons: []
    };
  }

  componentDidMount() {
    let buttons = [];
    let linkObject = this.props.links;

    if( linkObject["github"] ) {
       linkObject["download"] = linkObject["github"] + "/releases";
    }

    Object.entries(linkObject).forEach(([key, value]) => {
      if (value) {
        buttons.push(
          <Button
            key={key}
            startEnhancer={() => iconTable[key].logo}
            size={SIZE.compact}
            kind={KIND.minimal}
            //shape={SHAPE.round}
            overrides={buttonOverrides}
            $as="a"
            href={value}
            target="_blank"
            width="50px"
          />
        )

        if (this.props.view === "#desktop") {
          let num = test.length;
          buttons.push(
            <Block as="br" key={num} />
          )
        }
      }

      this.setState({
        buttons: buttons
      })
    });

  }

  render() {
    return (
      <div>
        {this.state.buttons}
        </div>
    )
  }
}
