import React from "react";
import ProjectList from './ProjectList'
import SearchInput from './SearchInput'
import {styled} from 'baseui';
import {Block} from 'baseui/block';
import {StatefulSelect, SIZE} from 'baseui/select';

export const HEADER_BREAKPOINT = '@media screen and (min-width: 640px)';

const OneColumn = styled('div', ({$theme}) => ({
  paddingTop: '120px',
  display: 'none',
  '@media screen and (max-width: 675px)': {
    display: 'block',
  },
}));

const TwoColumn = styled('div', ({$theme}) => ({
  paddingTop: '120px',
  display: 'none',
  '@media screen and (min-width: 675px) and (max-width: 1000px)': {
    display: 'block',
  },
}));

const ThreeColumn = styled('div', ({$theme}) => ({
  paddingTop: '120px',
  display: 'none',
  '@media screen and (min-width: 1000px)': {
    display: 'block',
  },
}));

const CATEGORIES = [
  {id: 0, category: '', label: 'All Projects'},
  {id: 1, category: 'machinelearning', label: 'Machine Learning'},
  {id: 2, category: 'datainfra', label: 'Data Infrastructure'},
  {id: 3, category: 'coreinfra', label: 'Core Infrastructure'},
  {id: 4, category: 'mobile', label: 'Mobile'},
  {id: 5, category: 'web', label: 'Web'},
  {id: 6, category: 'visualization', label: 'Data Visualization'},
];

const Headline = styled('div', ({$theme}) => ({
  margin: '0px',
  marginLeft: '10px',
  width: '100%',
  ...$theme.typography.font900
}));

export default class Projects extends React.Component {
  constructor() {
    super()
    this.state = {
      projects: [],
      filteredProjects: [],
      search: '',
      category: {}
    }
  }

  componentWillMount() {
    let state = window.store.getState()
    let list = state.projectState.projects;

    this.setState({
      projects: list,
      filteredProjects: list,
      search: '',
      category: {}
    })
  }

  filterProjects = (projectFilter) => {
    let category = (this.state.category.category === undefined) ? '' : this.state.category.category;
    let filteredProjects = this.state.projects
    let filter = (projectFilter === undefined) ? this.state.search : projectFilter;

    filteredProjects = filteredProjects.filter((project) => {
      let projectName = project.name.toLowerCase()
      let projectCategory = project.category

      return ((projectName.indexOf(
        filter.toLowerCase()) !== -1) &&
          ((projectCategory === category) || (category === '')))
    })
    this.setState({
      filteredProjects: filteredProjects,
      search: filter
    })
  }

  handleSelect = (select) => {
    let category = (select.value.length) ? select.value[0] : {id: 0, category: '', label: 'All Projects'};
    this.setState({category: category},
      () => this.filterProjects()
    );
  }

  render() {
    return (
      <div>
        <OneColumn>
          <Block
            display="grid"
            justifyItems="center"
            justifyContent="center"
            gridTemplateColumns={'repeat(1, auto)'}
            gridGap="scale400"
            width="100%"
          >
            <Block
              display="grid"
              justifyContent="left"
              gridGap="scale400"
              width="100%"
            >
              <Headline>Projects</Headline>
            </Block>
            <SearchInput
              projects={this.state.filteredProjects}
              match={this.props.match}
              onChange={this.filterProjects}
            />
            <StatefulSelect
              size={SIZE.compact}
              options={CATEGORIES}
              labelKey="label"
              valueKey="id"
              placeholder="All Projects"
              onChange={this.handleSelect}
              value={this.state.category.label}
              clearable="false"
              overrides={{
                Root: {
                  style: {
                    width: '300px'
                  }
                }
              }}
            />
          </Block>
          <Block height='35px'/>
          <Block
            display="grid"
            justifyItems="center"
            justifyContent="center"
            gridTemplateColumns={'repeat(1, auto)'}
            gridGap="scale400"
            width="100%"
          >
            <ProjectList columns="1" projects={this.state.filteredProjects} />
          </Block>
        </OneColumn>
          <TwoColumn>
            <Block
              display="grid"
              justifyItems="center"
              justifyContent="center"
              gridTemplateColumns={'repeat(2, auto)'}
              gridGap="scale400"
              width="100%"
            >
            <Block
              display="grid"
              justifyContent="left"
              gridGap="scale400"
              width="100%"
            >
              <Headline>Projects</Headline>
            </Block>
            <Block />
              <SearchInput
                projects={this.state.filteredProjects}
                match={this.props.match}
                onChange={this.filterProjects}
                overrides={{
                  Root: {
                    style: {
                      width: '300px',
                    },
                  }
                }}
              />
              <StatefulSelect
                size={SIZE.compact}
                options={CATEGORIES}
                labelKey="label"
                valueKey="id"
                placeholder="All Projects"
                onChange={this.handleSelect}
                value={this.state.category.label}
                clearable="false"
                overrides={{
                  Root: {
                    style: {
                      width: '300px',
                      marginBottom: '35px'
                    },
                  }
                }}
              />
              <ProjectList columns="2" projects={this.state.filteredProjects} />
            </Block>
          </TwoColumn>
          <ThreeColumn>
            <Block
              display="grid"
              justifyItems="center"
              justifyContent="center"
              gridTemplateColumns={'repeat(3, auto)'}
              gridAutoColumns="max-content"
              gridGap="scale400"
              width="100%"
            >
              <Block
                display="grid"
                justifyContent="left"
                width="100%"
              >
                <Headline>Projects</Headline>
              </Block>
              <Block />
              <Block />
              <SearchInput
                display="grid"
                projects={this.state.filteredProjects}
                match={this.props.match}
                onChange={this.filterProjects}
                overrides={{
                  Root: {
                    style: {
                      width: '305px',

                      margin: '10px',
                      marginBottom: '35px',
                    },
                  }
                }}
              />
              <StatefulSelect
                display="grid"
                size={SIZE.compact}
                options={CATEGORIES}
                labelKey="label"
                valueKey="id"
                placeholder="All Projects"
                onChange={this.handleSelect}
                value={this.state.category.label}
                overrides={{
                  Root: {
                    style: {
                      width: '305px',
                      margin: '10px',
                      marginTop: '0px',
                      marginBottom: '35px',
                    },
                  }
                }}
              />
              <Block display="grid" width="305px" margin="10px" border="1px solid #000000"
                overrides={{
                  Root: {
                    style: {
                      width: '300px',
                      marginBottom: '35px',
                      margin: '30px'
                    },
                  }
                }}
              >
            </Block>
            <ProjectList columns="3" projects={this.state.filteredProjects} />
          </Block>
        </ThreeColumn>
      </div>
    );
  }
}
