import * as React from 'react';

type PropT = {
  size: number,
  color: string,
};

const IconDownload = (props: PropT) => (
  <svg 
    width={props.size}
    height={props.size}
    className="prefix__octicon prefix__octicon-mark-download"
    aria-hidden="true"
    viewBox="0 0 18 18" 
    version="1.1"
  >
    <g id="surface1">
      <path 
        style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(0%,0%,0%)", fillOpacity:1 }}
        d="M 8.421875 13.941406 C 8.441406 13.960938 8.460938 13.980469 8.480469 13.996094 C 8.492188 14.003906 8.5 14.011719 8.507812 
           14.015625 C 8.523438 14.027344 8.535156 14.035156 8.546875 14.042969 C 8.558594 14.050781 8.570312 14.058594 8.582031 14.066406 
           C 8.59375 14.070312 8.601562 14.078125 8.613281 14.085938 C 8.625 14.089844 8.640625 14.097656 8.652344 14.101562 C 8.664062 
           14.109375 8.675781 14.113281 8.6875 14.121094 C 8.699219 14.125 8.710938 14.128906 8.722656 14.132812 C 8.738281 14.136719 
           8.75 14.140625 8.761719 14.144531 C 8.773438 14.148438 8.789062 14.152344 8.800781 14.15625 C 8.8125 14.160156 8.828125 
           14.164062 8.839844 14.164062 C 8.855469 14.167969 8.867188 14.171875 8.882812 14.171875 C 8.894531 14.175781 8.90625 14.175781 
	   8.917969 14.175781 C 8.945312 14.179688 8.972656 14.183594 9 14.183594 C 9.027344 14.183594 9.054688 14.179688 9.082031 
	   14.175781 C 9.09375 14.175781 9.105469 14.175781 9.117188 14.171875 C 9.132812 14.171875 9.144531 14.167969 9.160156 14.164062 
	   C 9.171875 14.164062 9.1875 14.160156 9.199219 14.15625 C 9.214844 14.152344 9.226562 14.148438 9.238281 14.144531 C 9.25 
	   14.140625 9.261719 14.136719 9.277344 14.132812 C 9.289062 14.128906 9.300781 14.125 9.3125 14.121094 C 9.324219 14.113281 
	   9.335938 14.109375 9.347656 14.101562 C 9.359375 14.097656 9.375 14.089844 9.386719 14.085938 C 9.398438 14.078125 9.40625 
	   14.070312 9.417969 14.066406 C 9.429688 14.058594 9.441406 14.050781 9.453125 14.042969 C 9.464844 14.035156 9.476562 14.027344 
	   9.488281 14.019531 C 9.5 14.011719 9.507812 14.003906 9.519531 13.996094 C 9.539062 13.980469 9.558594 13.960938 9.574219 
	   13.945312 C 9.578125 13.945312 9.578125 13.941406 9.578125 13.941406 L 13.398438 10.125 C 13.714844 9.804688 13.714844 9.285156 
           13.398438 8.96875 C 13.078125 8.648438 12.558594 8.648438 12.238281 8.96875 L 9.816406 11.386719 L 9.816406 1.363281 C 9.816406 
           0.910156 9.453125 0.546875 9 0.546875 C 8.546875 0.546875 8.183594 0.910156 8.183594 1.363281 L 8.183594 11.386719 L 5.761719 
	   8.96875 C 5.441406 8.648438 4.921875 8.648438 4.601562 8.96875 C 4.285156 9.285156 4.285156 9.804688 4.601562 10.125 Z M 8.421875 13.941406 "
      />
      <path 
	style={{ stroke: "none", fillRule: "nonzero", fill: "rgb(0%,0%,0%)", fillOpacity:1 }}
	d="M 17.183594 8.726562 C 16.730469 8.726562 16.363281 9.09375 16.363281 9.546875 L 16.363281 15.816406 L 1.636719 15.816406 
	   L 1.636719 9.546875 C 1.636719 9.09375 1.269531 8.726562 0.816406 8.726562 C 0.367188 8.726562 0 9.09375 0 9.546875 L 0 16.636719 
	   C 0 17.089844 0.367188 17.453125 0.816406 17.453125 L 17.183594 17.453125 C 17.632812 17.453125 18 17.089844 18 16.636719 
	   L 18 9.546875 C 18 9.09375 17.632812 8.726562 17.183594 8.726562 Z M 17.183594 8.726562 "
      />
    </g>
  </svg>
);

export default IconDownload;
