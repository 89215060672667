import * as React from 'react';

type PropT = {
  size: number,
  color: string,
};

const IconGithub = (props: PropT) => (
  <svg
    width={props.size}
    height={props.size}
    className="prefix__octicon prefix__octicon-mark-github"
    viewBox="0 0 24 24"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      fill={'white'}
      d="M22.13 2.13C21.32 2.49001 20.44 2.74001 19.52 2.85001C20.46 2.29001 21.18 1.4 21.52 0.339996C20.64 0.859996 19.67 1.24 18.63 1.44C17.8 0.560002 16.62 0 15.31 0C12.8 0 10.77 2.03999 10.77 4.53999C10.77 4.89999 10.81 5.24 10.89 5.58C7.11 5.39 3.76 3.58 1.52 0.830002C1.13 1.5 0.9 2.28 0.9 3.11C0.9 4.69 1.7 6.08 2.92 6.89C2.17 6.87 1.47 6.66001 0.86 6.32001C0.86 6.34001 0.86 6.36 0.86 6.38C0.86 8.58 2.43 10.42 4.51 10.83C4.13 10.95 3.73 11 3.32 11C3.03 11 2.74 10.97 2.47 10.92C3.05 12.73 4.73 14.04 6.72 14.08C5.16 15.3 3.21 16.03 1.08 16.03C0.71 16.03 0.35 16.01 0 15.97C2.01 17.26 4.4 18.01 6.97 18.01C15.33 18.01 19.9 11.09 19.9 5.08C19.9 4.88 19.9 4.69001 19.89 4.49001C20.75 3.84001 21.52 3.04 22.13 2.13Z"
    />
  </svg>
);

export default IconGithub;
