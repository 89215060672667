// @flow
import React from "react";
import {
  HeaderNavigation,
  ALIGN,
  StyledNavigationItem as NavigationItem,
  StyledNavigationList as NavigationList,
} from 'baseui/header-navigation';
import {styled} from 'baseui';
import {StyledLink as Link} from 'baseui/link';
import MenuIcon from 'baseui/icon/menu';
import {Block} from 'baseui/block';
import {StatefulPopover, TRIGGER_TYPE} from 'baseui/popover';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';

const Hamburger = styled<{}>('div', ({$theme}) => ({
  display: 'block',
  userSelect: 'none',
  height: '32px',
  color: $theme.colors.mono100,
  paddingRight: $theme.sizing.scale800,
  paddingTop: $theme.sizing.scale100,
  cursor: 'pointer',
  '@media screen and (min-width: 640px)': {
    display: 'none',
  },
}));

const NavLinkDesktop = styled('div', ({$theme}) => ({
  color: $theme.colors.mono100,
  fontSize: $theme.typography.font300.fontSize,
  ':active': {
    color: $theme.colors.linkActive,
  },
}));

const NavLinkMobile = styled('div', ({$theme}) => ({
  color: $theme.colors.mono1000,
  fontSize: $theme.typography.font300.fontSize,
  ':active': {
    color: $theme.colors.linkActive,
  },
}));

const StickyHeader = styled('div', ({$theme}) => ({
  backgroundImage: 'url("assets/blackdot.png")',
  backgroundSize: '100% 50px',
  backgroundRepeat: 'no-repeat',
  paddingBottom: '50px',
  position: 'fixed',
  top: 0,
  'z-index': 3,
  '@media screen and (max-width: 768px)': {
    width: document.body.offsetWidth,
  },
  '@media screen and (min-width: 768px)': {
    width: '100%',
  },
}));



export const HEADER_BREAKPOINT = '@media screen and (min-width: 640px)';

export default class Navigation extends React.Component {
  render() {
    return (
      <StickyHeader>
      <HeaderNavigation
        overrides={{
          Root: {
            style: ({$theme}) => ({
              backgroundColor: '#000000',
              marginBottom: '50px',
              border: '0px',
              justifyContent: 'space-between',
              boxShadow: 'none',
            })
          }
        }}
      >
        <NavigationList $align={ALIGN.left}>
          <NavigationItem>
            <Block  paddingTop="10px" />
            <Link href="/"><img src="/assets/white-horiz-logo.png" height="30" alt="" /></Link>
          </NavigationItem>
        </NavigationList>
        <NavigationList $align={ALIGN.center} />
        <NavigationList $align={ALIGN.right}>
          <NavigationItem >
            <Block
              display="flex"
              overrides={{
                Block: {
                  style: {
                    display: 'none',
                    [HEADER_BREAKPOINT]: {
                      display: 'block',
                    },
                  },
                },
              }}
            >
              <Link href="/">
                <NavLinkDesktop>
                  About
                </NavLinkDesktop>
              </Link>
            </Block>
          </NavigationItem>
          <NavigationItem>
            <Block
              overrides={{
                Block: {
                  style: {
                    display: 'none',
                    [HEADER_BREAKPOINT]: {
                      display: 'block',
                    },
                  },
                },
              }}
            >
              <Link href="/#/community">
                <NavLinkDesktop>
                  Community
                </NavLinkDesktop>
              </Link>
            </Block>
          </NavigationItem>
          <NavigationItem>
            <Block
              overrides={{
                Block: {
                  style: {
                    display: 'none',
                    [HEADER_BREAKPOINT]: {
                      display: 'block',
                    },
                  },
                },
              }}
            >
              <Link href="/#/projects">
                <NavLinkDesktop>
                  Projects
                </NavLinkDesktop>
              </Link>
            </Block>
          </NavigationItem>
          <NavigationItem>
            <Block
              overrides={{
                Block: {
                  style: {
                    display: 'none',
                    [HEADER_BREAKPOINT]: {
                      display: 'block',
                    },
                  },
                },
              }}
            >
              <Link href="/#/principles">
                <NavLinkDesktop>
                  Principles
                </NavLinkDesktop>
              </Link>
            </Block>
          </NavigationItem>
          <NavigationItem>
            <Block
              overrides={{
                Block: {
                  style: {
                    display: 'none',
                    //height: '24px',
                    [HEADER_BREAKPOINT]: {
                      display: 'block',
                    },
                  },
                },
              }}
            >
              <Link href="/#/github">
                <NavLinkDesktop>
                  GitHub
                </NavLinkDesktop>
              </Link>
            </Block>
          </NavigationItem>
          <NavigationItem>
            <Block
              overrides={{
                Block: {
                  style: {
                    display: 'none',
                    //height: '24px',
                    [HEADER_BREAKPOINT]: {
                      display: 'block',
                    },
                  },
                },
              }}
            >
              <Link href="https://eng.uber.com">
                <NavLinkDesktop>
                  Blog
                </NavLinkDesktop>
              </Link>
            </Block>
          </NavigationItem>
        </NavigationList>
        <NavigationList $align={ALIGN.right}>
          <StatefulPopover
            triggerType={TRIGGER_TYPE.hover}
            overrides={{
              Body: {
                style: {
                  'z-index': '4'
                }
              }
            }}
            placement="bottomRight"
            content = {
              <div>
                <FlexGrid
                  flexGridColumnCount={1}
                  flexGridColumnGap="scale800"
                  flexGridRowGap="scale800"
                  paddingLeft="scale800"
                  paddingRight="scale800"
                  paddingTop="scale600"
                  paddingBottom="scale600"
                >
                  <FlexGridItem>
                    <Link href="/">
                      <NavLinkMobile>About</NavLinkMobile>
                    </Link>
                  </FlexGridItem>
                  <FlexGridItem>
                    <Link href="/#/community">
                      <NavLinkMobile>
                        Community
                      </NavLinkMobile>
                    </Link>
                  </FlexGridItem>
                  <FlexGridItem>
                    <Link href="/#/projects">
                      <NavLinkMobile>
                        Projects
                      </NavLinkMobile>
                    </Link>
                  </FlexGridItem>
                  <FlexGridItem>
                    <Link href="/#/principles">
                      <NavLinkMobile>
                        Principles
                      </NavLinkMobile>
                    </Link>
                  </FlexGridItem>
                  <FlexGridItem>
                    <Link href="/#/github">
                      <NavLinkMobile>
                        GitHub
                      </NavLinkMobile>
                    </Link>
                  </FlexGridItem>
                  <FlexGridItem>
                    <Link href="https://eng.uber.com">
                      <NavLinkMobile>
                        Blog
                      </NavLinkMobile>
                    </Link>
                  </FlexGridItem>
                </FlexGrid>
              </div>
            }
            accessibilityType={'tooltip'}
          >
            <Hamburger role="button">
              <MenuIcon size={32} />
            </Hamburger>
          </StatefulPopover>
        </NavigationList>
        </HeaderNavigation>
      </StickyHeader>
    );
  }
}
