import React from "react";
import {styled} from 'baseui';
import {Button, SIZE, KIND} from 'baseui/button';
import {Block} from 'baseui/block';
import {Avatar} from 'baseui/avatar';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import IconGithub from './icons/IconGithub0';
import ReactSimpleCarousel from "react-plain-carousel";
import {TriangleLeft, TriangleRight} from 'baseui/icon';

const CarouselHeadline = styled('div', ({$theme}) => ({
  ...$theme.typography.font600
}));

const CarouselDescription = styled('div', ({$theme}) => ({
  ...$theme.typography.font300
}));

const squaredOverrides = {
  Avatar: {
    style: ({$theme}) => ({
      borderTopLeftRadius: $theme.borders.radius100,
      borderTopRightRadius: $theme.borders.radius100,
      borderBottomRightRadius: $theme.borders.radius100,
      borderBottomLeftRadius: $theme.borders.radius100,
    }),
  },
  Root: {
    style: ({$theme}) => ({
      borderTopLeftRadius: $theme.borders.radius100,
      borderTopRightRadius: $theme.borders.radius100,
      borderBottomRightRadius: $theme.borders.radius100,
      borderBottomLeftRadius: $theme.borders.radius100,
    }),
  },
};

const itemProps = {
  backgroundColor: '#F8F9F9',
  height: 'scale900',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const itemHeadlineProps = {
  backgroundColor: '#F8F9F9',
  height: 'scale1600',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export default class CarouselListMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: [],
      carousel: {},
      index: 0
    };
  }

  next = () => {
    this.state.carousel.next();
  };

  prev = () => {
    this.state.carousel.prev();
  };

  handleMount = carousel => {
    this.setState({ carousel });
  };

  handleTransitionEnd = ({ index }) => {
    if (index > -1) {
      this.setState({ index });
    }
    else {
      this.setState({index: this.props.slides.length - 1})
    }
  };
/*
  componentWillMount() {
    this.setState({
      slides: this.props.slides.map(function(item) {
        */
  render() {
    return (
      <div>
        <ReactSimpleCarousel isInfinity autoplay onMount={this.handleMount} onTransitionEnd={this.handleTransitionEnd}>
          {this.props.slides.map(function(item) {
            return (
              <div key={item.id} style={{ width: 400, height: 250, backgroundColor: "#F8F9F9", border: "1 px solid black" }}>
                <Block
                  display="grid"
                  gridTemplateColumns={'repeat(2, auto)'}
                  marginLeft="0px"
                  marginRight="0px"
                  width="100%"
                >
                  <Block justifyItems="center" justifyContent="center" paddingLeft="40px" paddingTop="50px">
                  <Avatar
                    overrides={squaredOverrides}
                    size="85px"
                    src={item.image}
                  />
                  </Block>
                  <Block>
                    <FlexGrid
                      flexGridColumnCount={1}
                    >
                      <FlexGridItem {...itemHeadlineProps}>
                        <CarouselHeadline>
                          {item.headline}
                        </CarouselHeadline>
                      </FlexGridItem>
                      <FlexGridItem {...itemProps}>
                        <CarouselDescription>
                          {item.line_1}
                        </CarouselDescription>
                      </FlexGridItem>
                      <FlexGridItem {...itemProps}>
                        <CarouselDescription>
                          {item.line_2}
                        </CarouselDescription>
                      </FlexGridItem>
                      <FlexGridItem {...itemProps}>
                        <CarouselDescription>
                          {item.line_3}
                        </CarouselDescription>
                      </FlexGridItem>
                      <FlexGridItem {...itemProps}></FlexGridItem>
                      <FlexGridItem {...itemProps}>
                        <Button
                          size={SIZE.compact}
                          overrides={{
                            BaseButton: {
                              style: {
                                width: '150px',
                                margin: '10px',
                                backgroundColor: '#ff8d00',
                                ":hover": {
                                  backgroundColor: '#ED6F0E',
                                }
                              }
                            }
                          }}
                          startEnhancer={() => <IconGithub size="18" />}
                          $as="a"
                          href={item.link}
                          target="_blank"
                        >
                          View on GitHub
                        </Button>
                      </FlexGridItem>
                    </FlexGrid>
                  </Block>
                </Block>
              </div>
            )
          })}
        </ReactSimpleCarousel>
        <Block backgroundColor="#F8F9F9" display="flex" justifyItems="center" justifyContent="center" alignItems="center" width="100%">
          <Button
            kind={KIND.minimal}
            size={SIZE.compact}
            onClick={() => this.prev()}
          >
            <TriangleLeft size={36} />
          </Button>
          {this.state.index + 1} / {this.props.slides.length}
          <Button
            kind={KIND.minimal}
            size={SIZE.compact}
            onClick={() => this.next()}
          >
            <TriangleRight size={36} />
          </Button>
        </Block>
      </div>
    )
  }
}
