// @flow
import React from 'react';
import {Block} from 'baseui/block';
import {styled} from 'baseui';
import {StatefulTabs, Tab} from 'baseui/tabs';
import CommunityList from './CommunityList'

const Headline = styled('div', ({$theme}) => ({
  margin: '0px',
  marginLeft: '0px',
  width: '100%',
  ...$theme.typography.font900
}));

const SummaryOne = styled('div', ({$theme}) => ({
  paddingTop: '20px',
  paddingBottom: '20px',
  width: '375px',
  ...$theme.typography.font400
}));

const SummaryTwo = styled('div', ({$theme}) => ({
  paddingTop: '20px',
  paddingBottom: '20px',
  width: '620px',
  ...$theme.typography.font400
}));

const SummaryThree = styled('div', ({$theme}) => ({
  paddingTop: '20px',
  paddingBottom: '20px',
  width: '900px',
  ...$theme.typography.font400
}));

const OneColumn = styled('div', ({$theme}) => ({
  display: 'none',
  '@media screen and (max-width: 675px)': {
    display: 'block',
  },
}));

const TwoColumn = styled('div', ({$theme}) => ({
  display: 'none',
  '@media screen and (min-width: 675px) and (max-width: 1000px)': {
    display: 'block',
  },
}));

const ThreeColumn = styled('div', ({$theme}) => ({
  display: 'none',
  '@media screen and (min-width: 1000px)': {
    display: 'block',
  },
}));

export default class Affiliations extends React.Component {
  constructor() {
    super()
    this.state = {
      affiliations: [],
      summary: {}
    }
  }

  componentWillMount() {
    let state = window.store.getState()
    let community = state.communityState.community;

    this.setState({
      affiliates: community.affiliates,
      contributions: community.contributions,
      summary: community.summary
    })
  }

  render() {
    return (
      <div>
        <Block
          display="grid"
          justifyItems="center"
          justifyContent="center"
          width="100%"
          paddingTop="120px"
        >
          <Block
            display="grid"
            justifyItems="left"
            justifyContent="left"
            gridTemplateColumns={'repeat(1, auto)'}
            gridGap="scale600"
            width="100%"
          >
            <Block>
              <Headline>Community</Headline>
              <OneColumn>
                <SummaryOne>
                  {this.state.summary}
                </SummaryOne>
              </OneColumn>
              <TwoColumn>
                <SummaryTwo>
                  {this.state.summary}
                </SummaryTwo>
              </TwoColumn>
              <ThreeColumn>
                <SummaryThree>
                  {this.state.summary}
                </SummaryThree>
              </ThreeColumn>
            </Block>
            <StatefulTabs initialState={{activeKey: '0'}} renderAll>
              <Tab title="Affiliations">
                <OneColumn>
                  <Block
                    display="grid"
                    justifyItems="center"
                    justifyContent="center"
                    gridTemplateColumns={'repeat(1, auto)'}
                    gridGap="scale1200"
                    width="100%"
                    paddingTop="25px"
                  >
                    <CommunityList list={this.state.affiliates} />
                  </Block>
                </OneColumn>
                <TwoColumn>
                  <Block
                    display="grid"
                    justifyItems="center"
                    justifyContent="center"
                    gridTemplateColumns={'repeat(2, auto)'}
                    gridGap="scale1200"
                    width="100%"
                    paddingTop="25px"
                  >
                    <CommunityList list={this.state.affiliates} />
                  </Block>
                </TwoColumn>
                <ThreeColumn>
                  <Block
                    display="grid"
                    justifyItems="center"
                    justifyContent="center"
                    gridTemplateColumns={'repeat(3, auto)'}
                    gridGap="scale1200"
                    width="100%"
                    paddingTop="25px"
                  >
                    <CommunityList list={this.state.affiliates} />
                  </Block>
                </ThreeColumn>
              </Tab>
            </StatefulTabs>
          </Block>
        </Block>
      </div>
    );
  }
}
