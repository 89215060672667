import {
  PAGE_CONTENT,
  PAGE_SETTINGS,
  COMMUNITY,
  PRINCIPLES,
  PROJECTS,
  ABOUT,
  GITHUB,
  TRADEMARKS,
  FOOTER
} from '../constants/index'

export function addContent(payload) {
  return { type: PAGE_CONTENT, content: payload };
}

export function addSettings(payload) {
  return { type: PAGE_SETTINGS, settings: payload };
}

export function addProjects(payload) {
  return { type: PROJECTS, projects: payload };
}

export function addCommunity(payload) {
  return { type: COMMUNITY, community: payload };
}

export function addPrinciples(payload) {
  return { type: PRINCIPLES, principles: payload };
}

export function addAbout(payload) {
  return { type: ABOUT, about: payload };
}

export function addGithub(payload) {
  return { type: GITHUB, github: payload };
}

export function addTrademarks(payload) {
  return { type: TRADEMARKS, trademarks: payload };
}

export function addFooter(payload) {
  return { type: FOOTER, footer: payload };
}
