import * as React from 'react';

type PropT = {
  size: number,
  color: string,
};

const IconGitter = (props: PropT) => (
  <svg
    width={props.size}
    height={props.size}
    className="prefix__octicon prefix__octicon-mark-github"
    viewBox="0 0 24 24"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      //fill={props.color}
      d="M12.103 0C18.666 0 24 5.485 24 11.997c0 6.51-5.33 11.99-11.9 11.99L0 24V11.79C0 5.28 5.532 0 12.103 0zm.116 4.563c-2.593-.003-4.996 1.352-6.337 3.57-1.33 2.208-1.387 4.957-.148 7.22L4.4 19.61l4.794-1.074c2.745 1.225 5.965.676 8.136-1.39 2.17-2.054 2.86-5.228 1.737-7.997-1.135-2.778-3.84-4.59-6.84-4.585h-.008z"
    />
  </svg>
);

export default IconGitter;
