import * as React from 'react';
import {Block} from 'baseui/block';
import {Button, SIZE, KIND} from 'baseui/button';
import {styled} from 'baseui';
import {Card} from 'baseui/card';
import {Avatar} from 'baseui/avatar';
import ArrowRight from 'baseui/icon/arrow-right';
import CarouselList from './CarouselList'
import CarouselListMobile from './CarouselListMobile'

const buttonOverrides = { BaseButton: {
  style: ({$theme}) => {
    return {
      ...$theme.typography.font400,
      textDecoration: 'none',
      color: $theme.colors.mono1000,
      width: '150px',
      justifyContent: 'left',
      ':hover': {
        backgroundColor: $theme.colors.mono100,
      },
    };
  }
}};

const Headline = styled('div', ({$theme}) => ({
  marginTop: '100px',
  marginBottom: '25px',
  textAlign: 'center',
  width: '100%',
  backgroundColor: $theme.colors.mono1000,
  color: $theme.colors.mono100,
  ...$theme.typography.font900
}));

const SubHeadline = styled('div', ({$theme}) => ({
  margin: '0px',
  marginLeft: '0px',
  width: '100%',
  textDecoration: 'none',
  color: '#000000',
  ...$theme.typography.font800
}));


const ContentBody = styled('div', ({$theme}) => ({
  margin: '0px',
  marginLeft: '0px',
  width: '100%',
  color: $theme.colors.mono800,
  ...$theme.typography.font400
}));

const AffiliationHeadline = styled('div', ({$theme}) => ({
  textDecoration: 'none',
  width: '100%',
  color: $theme.colors.mono1000,
  ...$theme.typography.font500
}));

const AffiliationBody = styled('div', ({$theme}) => ({
  textDecoration: 'none',
  width: '100%',
  color: $theme.colors.mono1000,
  ...$theme.typography.font400
}));

const squaredOverrides = {
  Avatar: {
    style: ({$theme}) => ({
      borderTopLeftRadius: $theme.borders.radius100,
      borderTopRightRadius: $theme.borders.radius100,
      borderBottomRightRadius: $theme.borders.radius100,
      borderBottomLeftRadius: $theme.borders.radius100,
    }),
  },
  Root: {
    style: ({$theme}) => ({
      borderTopLeftRadius: $theme.borders.radius100,
      borderTopRightRadius: $theme.borders.radius100,
      borderBottomRightRadius: $theme.borders.radius100,
      borderBottomLeftRadius: $theme.borders.radius100,
    }),
  },
};

const Mobile = styled('div', ({$theme}) => ({
  //paddingTop: '120px',
  display: 'none',
  '@media screen and (max-width: 768px)': {
    display: 'block',
  },
}));

const Desktop = styled('div', ({$theme}) => ({
  display: 'none',
  '@media screen and (min-width: 768px)': {
    display: 'block',
  },
}));

const Inner = styled('div', ({$theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '75px',
  borderBottom: `2px solid ${$theme.colors.mono900}`,
  width: '100%',
}));

class Blog extends React.Component {
  render() {
    let that = this;

    return (
      that.props.list.map(function(item) {
        return (
          <Card
            width="100%"
            overrides={{Root: {style: {width: '100%'}}}}
            key={item.id}
          >
            <Block
              display="grid"
              gridTemplateColumns={'repeat(3, auto)'}
              width="100%"
            >
            <Avatar
              overrides={squaredOverrides}
              name="user name #3"
              size="120px"
              src={'../' + item.image}
            />
            <Block paddingLeft="30px" paddingRight="10px">
              <Block as="br" />
                <AffiliationHeadline>
                  {item.headline}
                </AffiliationHeadline>
                <AffiliationBody>
                  {item.description}
                </AffiliationBody>
              </Block>
              <Block height="100%" paddingTop="scale1000">
                <Button style={{width: '100%'}}
                  endEnhancer={() => <ArrowRight size={36} />}
                  size={SIZE.compact}
                  kind={KIND.minimal}
                  $as="a"
                  href={item.link}
                  target="_blank"
                  overrides={buttonOverrides}
                 />
              </Block>
            </Block>
          </Card>
        )
      })
    )
  }
}

class PageLinks extends React.Component {
  render() {
    let that = this;

    return (
      that.props.list.map(function(item) {
        return (
          <Inner key={item.id}>
            <Block
              display="grid"
              gridTemplateColumns={'repeat(4, auto)'}
              marginLeft="0px"
              marginRight="0px"
              width="100%"
            >
              <Block paddingBottom="10px" display="grid">
                <SubHeadline>
                  {item.text}
                </SubHeadline>
              </Block>
              <Block />
              <Block />
              <Block width="50px" alignContent="right" paddingBottom="10px" display="grid" marginRight="0px">
                <Button style={{width: '100%'}}
                  endEnhancer={() => <ArrowRight size={48} />}
                  size={SIZE.compact}
                  kind={KIND.minimal}
                  $as="a"
                  href={item.link}
                  target={item.target}
                  overrides={buttonOverrides}
                />
              </Block>
            </Block>
          </Inner>
        )
      })
    )
  }
}

export default class Home extends React.Component {
  constructor() {
    super()
    this.state = {
      about: [],
      project: []
    }
  }

  componentWillMount() {
    let state = window.store.getState()
    let about = state.aboutState;

    this.setState({
      about: about.about
    })
  }

  render() {
    const tagline = this.state.about.tagline;
    const blogposts = this.state.about.blogposts;
    const pagelinks = this.state.about.pagelinks;
    const carousel = this.state.about.carousel;

    return (
      <div>
        <Block
          backgroundColor="#000000"
          display="grid"
          justifyItems="center"
          justifyContent="center"
          width="100%"
        >
          <Block
            width="80%"
            paddingBottom="20px"
            justifyItems="center"
            justifyContent="center"
          >
            <Headline>{tagline}</Headline>
          </Block>
        </Block>

        <Desktop>
          <Block
            backgroundImage="url('assets/blackdot.png')"
            paddingLeft="calc(50% - 400px)"
            paddingRight="calc(50% - 400px)"
            backgroundSize="100% 200px"
            backgroundRepeat="no-repeat"
            paddingBottom="50px"
          >
            <CarouselList slides={carousel} />
          </Block>
        </Desktop>

        <Mobile>
          <Block
            backgroundImage="url('assets/blackdot.png')"
            paddingLeft="calc(50% - 200px)"
            paddingRight="calc(50% - 200px)"
            backgroundSize="100% 200px"
            backgroundRepeat="no-repeat"
            paddingBottom="50px"
          >
            <CarouselListMobile slides={carousel} />
          </Block>
        </Mobile>

        <Block
          paddingTop="50px"
          display="grid"
          justifyItems="center"
          width="100%"
        >
          <Block
            width="80%"
            paddingBottom="10px"
            display="grid"
            justifyItems="left"
            justifyContent="left"
          >
            <SubHeadline>{blogposts.headline}</SubHeadline>
          </Block>
          <Block
            width="80%"
            display="grid"
            justifyItems="left"
            justifyContent="left"
            paddingBottom="35px"
          >
            <ContentBody>{blogposts.description}</ContentBody>
          </Block>

          <Block
            width="80%"
            display="grid"
            justifyItems="left"
            justifyContent="left"
            paddingBottom="35px"
          >
            <Mobile>
              <Block
                display="grid"
                justifyItems="left"
                justifyContent="left"
                gridTemplateColumns={'repeat(1, auto)'}
                gridGap="scale900"
                width="100%"
              >
                <Blog list={blogposts.list} />
                <PageLinks list={pagelinks} />
              </Block>
            </Mobile>

            <Desktop>
              <Block
                display="grid"
                justifyItems="left"
                justifyContent="left"
                gridTemplateColumns={'repeat(2, auto)'}
                gridGap="scale900"
                width="100%"
              >
                <Blog list={blogposts.list} />
                <PageLinks list={pagelinks} />
              </Block>
            </Desktop>
          </Block>
        </Block>
      </div>
    );
  }
}
