import React, { Component } from 'react'
import {SIZE} from 'baseui/select';
import {StatefulInput} from 'baseui/input';
import {Block} from 'baseui/block';
import Search from 'baseui/icon/search';

function Before() {
  return (
    <Block
      display="flex"
      alignItems="center"
      paddingLeft="scale500"
    >
      <Search size="18px" />
    </Block>
  );
}

export default class SearchInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projectFilter: ""
    }
  }

  handleChange = (e) => {
    this.setState({
      projectFilter: e.target.value
    })
    this.props.onChange(e.target.value)
  }

  render() {
    return (
      <div width="300px">
        <StatefulInput
          overrides={{Before, Root: {
            style: {
              width: '300px',
            },
          }}}
          size={SIZE.compact}
          placeholder="Search by name..."
          value={this.state.projectFilter}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}
