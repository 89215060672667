import * as React from 'react';

type PropT = {
  size: number,
  color: string,
};

const IconGitter = (props: PropT) => (
  <svg
    width={props.size}
    height={props.size}
    className="prefix__octicon prefix__octicon-mark-github"
    viewBox="0 0 512 512"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      //fill={props.color}
      d="M143.155,315.746h-45.28V26.001h45.28V315.746z M233.448,94.371h-45.28v391.628h45.28V94.371z M323.832,94.371H278.55    v391.628h45.282V94.371z M414.125,94.282h-45.282v221.913h45.282V94.282z"
    />
  </svg>
);

export default IconGitter;
