import { combineReducers } from 'redux';
import {
  PAGE_CONTENT,
  PAGE_SETTINGS,
  COMMUNITY,
  PRINCIPLES,
  PROJECTS,
  ABOUT,
  FOOTER,
  GITHUB,
  TRADEMARKS
} from '../constants/index'

const initialState = {
  content: {},
  settings: {},
  community: {},
  principles: {},
  projects: {},
  about: {},
  footer: {},
  github: {},
  trademarks: {}
};


function contentState(state = initialState.content, action) {
  switch (action.type) {
    case PAGE_CONTENT:
      return Object.assign(state, action);
    default:
      return state
  }
}

function settingsState(state = initialState.settings, action) {
  switch (action.type) {
    case PAGE_SETTINGS:
      return Object.assign(state, action);
    default:
      return state
  }
}

function communityState(state = initialState.community, action) {
  switch (action.type) {
    case COMMUNITY:
      return Object.assign(state, action);
    default:
      return state
  }
}

function principlesState(state = initialState.principles, action) {
  switch (action.type) {
    case PRINCIPLES:
      return Object.assign(state, action);
    default:
      return state
  }
}

function projectState(state = initialState.projects, action) {
  switch (action.type) {
    case PROJECTS:
      return Object.assign(state, action);
    default:
      return state
  }
}

function aboutState(state = initialState.about, action) {
  switch (action.type) {
    case ABOUT:
      return Object.assign(state, action);
    default:
      return state
  }
}

function footerState(state = initialState.footer, action) {
  switch (action.type) {
    case FOOTER:
      return Object.assign(state, action);
    default:
      return state
  }
}

function githubState(state = initialState.github, action) {
  switch (action.type) {
    case GITHUB:
      return Object.assign(state, action);
    default:
      return state
  }
}

function trademarksState(state = initialState.trademarks, action) {
  switch (action.type) {
    case TRADEMARKS:
      return Object.assign(state, action);
    default:
      return state
  }
}

export const reducers = combineReducers({
  contentState,
  settingsState,
  communityState,
  principlesState,
  projectState,
  aboutState,
  footerState,
  githubState,
  trademarksState
})
